import api from '@/api/settings'
// import {notify} from "@/helpers/notifyHelper";

export default {
	data () {
		return {
			form: null
		}
	},
	created() {
		this.getData()
	},
	methods: {
		getData() {
			api.getAll(this)
				.then(response => {
					this.form = {}
					response.data.map(item => {
						this.form[item.id] = {
							id: item.id,
							name: item.name,
							value: item.value
						}
					})
				})
		},
		save () {
			Object.keys(this.form).map(key => {
				const form = {
					name: this.form[key].name,
					value: this.form[key].value,
				}
				api.update(this, form, key)
			})
		}
	}
}
