import ApiBase from '@/api/base'
import {notify} from "@/helpers/notifyHelper";

class Settings {

	static getByName (self, name) {
		return self.http('get', `${ApiBase.baseUrl()}/settings/data/${name}`)
	}

	static getAll (self) {
		return self.http('get', `${ApiBase.baseUrl()}/settings/data`)
	}

	static update (self, data, id) {
		return self.http('put', `${ApiBase.baseUrl()}/settings/${id}`, data)
			.then(response => {
				notify(self, 'success', response.data.message)
			})
	}
}

export default Settings

